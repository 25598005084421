<template>
	<section :id="id">
		<div class="info-wrapper">
			<a href="#home"><img class="logo" src="/static-assets/logo-green.svg" /></a>
			<div class="mobile-grid">
				<div class="address cols">
					<div>
						<a href="https://www.google.com/maps/place/Majern%C3%ADk+%26+Mih%C3%A1likov%C3%A1,+s.r.o.,+Cukrov%C3%A1+2272%2F14,+811+08+Bratislava,+Eslov%C3%A0quia/@48.1488523,17.1195514,15z/data=!4m2!3m1!1s0x476c895bbfb4a3f1:0x74cc37073b380c6a" target="_blank"><img src="/static-assets/address.svg" width="40" /></a>
					</div>
					<div class="desktop">
						Majernik &amp; Miháliková, s. r. o.<br />
						Cukrová 14<br />
						81108 Bratislava
					</div>
					<div class="mobile">
						<a href="https://www.google.com/maps/place/Majern%C3%ADk+%26+Mih%C3%A1likov%C3%A1,+s.r.o.,+Cukrov%C3%A1+2272%2F14,+811+08+Bratislava,+Eslov%C3%A0quia/@48.1488523,17.1195514,15z/data=!4m2!3m1!1s0x476c895bbfb4a3f1:0x74cc37073b380c6a" target="_blank">{{ $root.translate('Go') }}</a>
					</div>
				</div>
				<div class="tel-fax cols">
					<div>
						<a href="tel:+421(2)20910550"><img src="/static-assets/phone.svg" width="40" /></a>
					</div>
					<div class="desktop">
						Tel: <a href="tel:+421(2)20910550">+421 (2) 20910550</a>
					</div>
					<div class="mobile">
						<a href="tel:+421(2)20910550">{{ $root.translate('Call') }}</a>
					</div>
				</div>
				<div class="email cols" v-on:click="$root.openTypeform('https://form.typeform.com/c/F3s4vOom')">
					<div>
						<img src="/static-assets/message.svg" width="40" />
					</div>
					<div>
						{{ $root.translate('Message') }}
					</div>
				</div>
				<div class="social cols">
					<div>
						<a href="https://www.linkedin.com/company/majernik&mihalikova" target="_blank"><img src="/static-assets/linkedin.svg" width="40" /></a>
					</div>
					<div class="desktop">
						<a href="https://www.linkedin.com/company/majernik&mihalikova" target="_blank">{{ $root.translate('Contact via Linkedin') }}</a>
					</div>
					<div class="mobile">
						<a href="https://www.linkedin.com/company/majernik&mihalikova" target="_blank">Linkedin</a>
					</div>
				</div>
				<div class="vcard cols">
					<div>
						<a href="/static-assets/vCard.vcf" target="_blank" download><img src="/static-assets/vcard.svg" width="40" /></a>
					</div>
					<div>
						<a href="/static-assets/vCard.vcf" target="_blank" download>vCard</a>
					</div>
				</div>
			</div>
			<div class="membersOf">
				<div class="members-col">
					<div class="title desktop"><b>{{ $root.translate("We are a member of") }}:</b></div>
					<div class="title mobile"><b>{{ $root.translate("Member of") }}:</b></div>
					<div class="logosrow">
						<div class="logos">
							<a href="https://ponteslegal.eu" target="_blank"><img src="/static-assets/pontes.svg" width="90" /></a>
						</div>
						<div class="pontes-text">{{ $root.translate("PONTES is a group of more than 90 professionals who provide top quality legal services in the CEE region.") }}</div>
					</div>
				</div>
				<div class="members-col">
					<div class="title"><b>{{ $root.translate("Ranked in") }}:</b></div>
					<a href="https://www.legal500.com/firms/14995-majernik-mihalikova-s-r-o/20228-bratislava-slovakia/" target="_blank">The Legal 500</a><br />
					<a href="https://chambers.com/department/majernik-mihalikova-s-r-o-corporate-ma-europe-7:354:189:1:22668894" target="_blank">Chambers</a>
				</div>
			</div>
			<div class="legal-footer">
				<ul class="btns-text">
					<li><button type="button" v-on:click="showText(cdi)">{{ $root.translate('Compulsory disclosure of information') }}</button></li>
					<li><button type="button" v-on:click="showText(privacyPolicy)">{{ $root.translate('Privacy Policy') }}</button></li>
          <li><button type="button" v-on:click="showText(cookiesPolicy)">{{ $root.translate('Cookies Policy') }}</button></li>

          <!--					<li><a :href="$root.translate('https://www.dropbox.com/s/ivxqxxn0toqcqat/Privacy%20Policy_ENG.pdf?dl=0')" target="_blank">{{ $root.translate('Privacy Policy') }}</a></li>-->
<!--					<li><a :href="$root.translate('https://www.dropbox.com/s/j0juwonmu3txnqh/Cookie%20Policy_ENG.pdf?dl=0')" target="_blank">{{ $root.translate('Cookies Policy') }}</a></li>-->
				</ul>
				<div class="legal-text" v-if="legalTextActive != ''">
					<button type="button" class="btn-close" v-on:click="hideText">
						<svg viewBox="0 0 100 100">
		                    <line x1="0" y1="0" x2="100" y2="100" />
		                    <line x1="0" y1="100" x2="100" y2="00" />
		                </svg>
					</button>
					<img class="logo" src="/static-assets/logo-white.svg" />
          <div v-html="legalTextActive"/>
          <div id="legal-after"></div>
				</div>
			</div>
		</div>
		<div class="map-wrapper">
			<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2661.9973737051932!2d17.117362715834943!3d48.14885585835397!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476c895bbfb4a3f1%3A0x74cc37073b380c6a!2zTWFqZXJuw61rICYgTWlow6FsaWtvdsOhLCBzLnIuby4!5e0!3m2!1sen!2ses!4v1627398804899!5m2!1sen!2ses" allowfullscreen="" loading="lazy"></iframe>
		</div>
	</section>
</template>

<script>
export default {
	name: "Contact",
	props: {
		id: String,
    locale: String,
    cdi: String,
    privacyPolicy: String,
    cookiesPolicy: String,
	},
	data: function(){
		return {
			legalTextActive: '',
		}
	},
	methods: {
		showText: async function(text){
			this.legalTextActive = text[this.locale];
			document.querySelector('body').classList.add('noscroll');
		},
		hideText: function(){
			this.legalTextActive = '';
			document.querySelector('body').classList.remove('noscroll');
		},
	},
};
</script>

<style scoped lang="scss">

	section{
		background-color: var(--color1);
		width: 100%;
		min-height: 100vh;
		color: #FFF;
		font-size: 16px;
		display: flex;

		.info-wrapper{
			width: 30%;
			padding: 4%;

			.logo{
				max-width: 350px;
				margin-bottom: 25px;
			}

			.mobile-grid{

				.cols{
					display: flex;
					gap: 10px;
					align-items: center;
					margin-bottom: 10px;
					font-size: 14px;

					a{
						color: #FFF;
						text-decoration: none;
					}
				}

				.address{
				}

				.tel-fax{
				}

				.email{
					cursor: pointer;

					a{
						color: #FFF;
						text-decoration: none;
					}
				}

				.social{

				}
			}

			.membersOf{
				font-size: 14px;
				margin-top: 40px;
				margin-bottom: 40px;

				.title{
					margin-bottom: 10px;
				}

				.logosrow{
					display: flex;
					align-items: flex-end;
					gap: 15px;
					margin-bottom: 30px;

					.logos{
						display: flex;
						gap: 15px;
					}
				}

				.secondrow{
					display: flex;
					align-items: flex-end;
					gap: 15px;
				}

				a{
					color: #FFF;
					text-decoration: none;

					&:hover{
						text-decoration: underline;
					}
				}
			}

			.legal-footer{

				ul.btns-text{
					list-style: none;
					padding: 0;
					margin: 0;
					display: table;

					li{
						margin: 0 0 5px 0;

						button,
						a{
							padding: 0;
							margin: 0;
							font-size: 14px;
							color: #FFF;
							text-decoration: none;
							border: 0;
							outline: none;
							background: transparent;
							cursor: pointer;

							&:hover{
								text-decoration: underline;
							}
						}
					}
				}

				.legal-text{
					position: fixed;
					top: 0;
					left: 0;
					width: 100%;
					height: 100vh;
					overflow: auto;
					background: #FFF;
					color: #000;
					z-index: 3000;
					padding: 25px;

					.btn-close{
						border: 1px solid #000;
						cursor: pointer;
						border-radius: 999px;
						height: 50px;
						width: 50px;
						position: absolute;
						top: 40px;
						right: 40px;
						background-color: transparent;
						padding: 12px;

						svg{
							width: 100%;
		                    height: 100%;

		                    line{
		                        stroke: #000;
		                        stroke-width: 5px;
		                        transition: stroke 0.3s;
		                    }
						}
					}

					.logo{
						max-width: 450px;
						margin: 0 auto;
						display: block;
					}

					h2{
						font-size: 22px;
					}

					p{
						font-size: 16px;
					}

					table{
						border-collapse: collapse;
						border-spacing: 0;
						width: 100%;
						border: 1px solid #adb5bd;
					}

					th, td{
						text-align: left;
						vertical-align: text-top;
						padding: 8px;
					}

					#sup {
						font-size: smaller;
					}

					tr:nth-child(even){
						background-color: #eee
					}
				}
			}
		}

		.map-wrapper{
			width: 70%;

			iframe{
				border: 0;
				width: 100%;
				height: 100%;
			}
		}
	}

	@media (max-width: 1200px){

		section{

			.info-wrapper{
				width: 40%;
			}

			.map-wrapper{
				width: 60%;
			}
		}
	}

	@media (max-width: 768px){

		section{
			flex-direction: column;

			.info-wrapper{
				width: 100%;
				padding: 15px;

				.logo{
					margin-left: auto;
					margin-right: auto;
					display: block;
				}

				.mobile-grid{
					display: flex;
					justify-content: space-between;

					.cols{
						flex-direction: column;
					}
				}

				.membersOf{
					display: flex;
					justify-content: center;
					gap: 30px;

					.logosrow{

						.logos{
						}

						.pontes-text{
							display: none;
						}
					}
				}

				.legal-footer{

					ul.btns-text{
						display: block;

						li{
							text-align: center;
						}
					}
				}
			}

			.map-wrapper{
				width: 100%;
				height: 50vh;
			}
		}

	}
</style>
