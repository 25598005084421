<template>
	<section :id="id">
		<h2 class="title">
			<span>{{ title }}</span><br />
			<b>{{ subtitle }}</b>
		</h2>
		<div class="content-wrapper">
			<slot></slot>
		</div>
		<div class="footer">
			<button type="button" v-if="ctaLink != ''" v-on:click="$root.openTypeform(ctaLink)" class="btn-1 know-more">{{ ctaText }}</button>
		</div>
	</section>
</template>

<script>
export default {
	name: "Testimonies",
	props: {
		id: String,
		title: String,
		subtitle: String,
		ctaLink: String,
		ctaText: String
	},
};
</script>

<style scoped lang="scss">
	section{
		width: 100%;
		padding: 140px;
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		gap: 25px;

		.title{
			font-size: 24px;
			flex: 0;
			font-weight: normal;
			margin: 0;

			span{}

			b{
				color: var(--color1);
			}
		}

		.content-wrapper{
			flex: 1;
			display: flex;
		}

		.footer{
			flex: 0;
		}

	}

	@media (max-width: 1400px){

		section{
			padding: 50px 80px 50px 50px;
			gap: 50px;
		}

	}

	// @media (max-width: 991px){
	//
	// 	section{
	// 		min-height: 0;
	// 	}
	//
	// }

	@media (max-width: 768px){

		section{
			padding: 25px;
		}

	}
</style>
