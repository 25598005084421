<template>
	<div class="tab-content" :class="{'active': isActive}" :style="getStyles()">
		<div>
			<img class="testimony-image img-fluid" v-if="testimonyImage" :src="testimonyImage" />
			<div class="title-wrapper" v-if="title || subtitle">
				<span class="title" v-if="title">{{ title }}<span v-if="subtitle"> - </span></span><span class="subtitle" v-if="subtitle">{{ subtitle }}</span>
			</div>
			<div class="content">
				<slot></slot>
			</div>
		</div>
    <div class="testimonies-fake">
      <img class="testimony-image" src="/static-assets/logo-min.svg" width="100" />
      <div class="title-wrapper" v-if="title || subtitle">
        <span class="title">Majerník &amp; Miháliková</span>
      </div>
      <!-- <div class="content-fake">
        <i>{{ $root.translate('Typing') }} ...</i>
      </div> -->
    </div>
	</div>
</template>

<script>
export default {
	name: "TabItem",
	inject: ["activeTab"],
	props: {
		id: String,
		title: String,
		subtitle: String,
		backgroundImage: String,
		testimonyImage: String
	},
	computed: {
		isActive: function(){
			return this.activeTab == this.id;
		}
	},
	methods: {
		getStyles: function(){
			let output = '';
			if(this.backgroundImage){
				output += 'background-image: url(' + this.backgroundImage + ');';
			}
			return output;
		}
	}
};
</script>

<style scoped lang="scss">
	.tab-content{
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		display: none;
		padding-top: 40px;

		.testimony-image{
			margin-bottom: 10px;
		}

		.title-wrapper{
			margin-bottom: 15px;
			font-size: 18px;

			.title{
				font-weight: bold;
			}

			.subtitle{
				color: var(--color1);
			}
		}

		.content{
			font-size: 14px;
		}

		.testimonies-fake{
			display: none;
		}

		&.active{
			display: block;
		}

		&.testimonies{
			display: none;
			flex-direction: column;
			// justify-content: center;
			// align-items: center;
			padding: 40px;
			height: 100%;

			.title-wrapper{
				font-weight: bold;
				// text-align: center;

				.title{
				}

				.subtitle{
					color: var(--color1);
					font-size: 18px;
				}
			}

			.content{
				max-width: 70%;
				// text-align: center;
				font-size: 18px;
				padding: 20px;
				background: #eee;
				border-radius: 20px;
			}

			.testimonies-fake{
				display: block;
				text-align: right;
				margin-top: 40px;

				.content-fake{
					max-width: 70%;
					margin-right: 0;
					margin-left: auto;
					background: #FFF;
					border: 2px solid var(--color1);
					padding: 20px;
					border-radius: 20px;
					color: var(--color1);
					font-size: 18px;
				}
			}

			&.active{
				display: flex;
			}
		}
	}

	@media (max-width: 768px){

		.tab-content{

			&.testimonies{

				.content{
					max-width: 90%;
				}

				.testimonies-fake{

					.content-fake{
						max-width: 90%;
					}
				}
			}
		}
	}
</style>
