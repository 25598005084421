<template>
	<transition name="fade">
		<div id="typeform-content" v-if="active">
			<button type="button" class="btn-close" v-on:click="$root.typeform.active = false">
				<svg viewBox="0 0 100 100">
					<line x1="0" y1="0" x2="100" y2="100" />
					<line x1="0" y1="100" x2="100" y2="00" />
				</svg>
			</button>
			<iframe :src="src"></iframe>
		</div>
	</transition>
</template>

<script>
export default {
	name: "Typeform",
	props: {
		src: String,
		active: Boolean
	}
};
</script>

<style scoped lang="scss">

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s ease;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}

	#typeform-content{
		position: fixed;
		top: 0;
		left: 0;
		z-index: 2000;
		background: #FFF;
		width: 100%;
		height: 100vh;
		overflow: hidden;

		.btn-close{
			border: 1px solid #000;
			cursor: pointer;
			border-radius: 999px;
			height: 50px;
			width: 50px;
			position: absolute;
			top: 40px;
			right: 40px;
			background-color: transparent;
			padding: 12px;

			svg{
				width: 100%;
				height: 100%;

				line{
					stroke: #000;
					stroke-width: 5px;
					transition: stroke 0.3s;
				}
			}
		}

		iframe{
			width: 100%;
			height: 100vh;
		}
	}
</style>
