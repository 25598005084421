<template>
	<section :id="id">
		<div class="col1">
			<h2 class="title">
				<span>{{ title }}</span><br />
				<b>{{ subtitle }}</b>
			</h2>
			<div class="intro" v-if="$slots.intro">
				<slot name="intro"></slot>
			</div>
			<div class="mobile image">
				<img :src="$root.translate(image)" class="img-fluid" />
			</div>
			<slot name="tabs"></slot>
			<!-- <a v-if="ctaLink != ''" :href="ctaLink" class="btn-1 know-more" target="_blank">{{ ctaText }}</a> -->
			<button type="button" v-if="ctaLink != ''" v-on:click="$root.openTypeform(ctaLink)" class="btn-1 know-more">{{ ctaText }}</button>
		</div>
		<div class="col2" :class="{full: id == 'our-team'}">
			<img :src="$root.translate(image)" />
		</div>
	</section>
</template>

<script>
export default {
	name: "SectionTemplate1",
	props: {
		id: String,
		title: String,
		subtitle: String,
		image: String,
		ctaLink: String,
		ctaText: String
	},
};
</script>

<style scoped lang="scss">
	section{
		width: 100%;
		min-height: 100vh;
		display: flex;
		align-items: stretch;

		.col1{
			padding: 6%;
			position: relative;
			width: 40%;

			.title{
				font-size: 24px;
				margin-top: 0;
				margin-bottom: 40px;
				font-weight: normal;

				span{}

				b{
					color: var(--color1);
				}
			}

			.intro{
				margin-bottom: 100px;
				font-size: 17px;
			}

			.know-more{
				// position: absolute;
				// left: 140px;
				// bottom: 140px;
			}
		}

		.col2{
			width: 60%;
			position: relative;

			img{
				position: absolute;
				top: 0;
				left: 0;
				width: calc(100% - 70px);
				height: 100%;
				padding: 10%;
				object-fit: contain;
				object-position: center;
			}

			&.full{

				img{
					width: 100%;
					padding: 0;
					object-fit: cover;
				}
			}
		}

		&.inverted{

			.col1{
				order: 2;
			}

			.col2{
				order: 1;

				img{
					right: 0;
					left: auto;
				}
			}
		}
	}

	@media (max-width: 1400px){

		section{
			gap: 50px;
		}

	}

	@media (max-width: 1100px){

		section{

			.col1,
			.col2{
				width: 50%;
			}
		}

	}

	@media (max-width: 991px){

		section{

			.col1{
				width: 100%;

				.mobile{
					display: block;
				}

				.mobile.image{
					margin-bottom: 25px;
				}
			}

			.col2{
				display: none;
			}
		}

	}

	@media (max-width: 768px){

		section{

			.col1{
				padding: 15px;

				.intro{
					margin-bottom: 20px;
				}
			}
		}

	}
</style>
