<template>
	<section :id="id">
		<div class="col1">
			<div class="banner">
				<img src="/static-assets/news.svg" />
			</div>
			<div class="news-wrapper" v-if="news">
				<article v-for="article in news">
					<iframe :src="article.iframe" frameborder="0" allowfullscreen="" title="Publicación integrada"></iframe>
					<a :href="article.link" class="btn-1 know-more" target="_blank">{{ $root.translate('More') }}</a>
				</article>
			</div>
		</div>
		<div class="col2">
			<h2 class="title">
				<span>{{ title }}</span><br />
				<b>{{ subtitle }}</b>
			</h2>
			<p>
				{{ $root.translate("Follow our Linkedin channel and stay informed") }}
			</p>
			<img class="news-girl" src="/static-assets/illustrations/news-girl-reading.svg" />
			<button type="button" v-if="ctaLink != ''" v-on:click="$root.openTypeform(ctaLink)" class="btn-1 know-more">{{ ctaText }}</button>
		</div>
	</section>
</template>

<script>
export default {
	name: "News",
	props: {
		id: String,
		title: String,
		subtitle: String,
		ctaLink: String,
		ctaText: String,
    news: null
	},
};
</script>

<style scoped lang="scss">
	section{
		width: 100%;
		padding: 140px 140px 40px;
		min-height: 100vh;
		display: grid;
		grid-template-columns: calc(66% - 15px) calc(33% - 15px);
		gap: 30px;

		.col1{

			.banner{

				img{
					width: 100%;
					height: 150px;
					object-fit: contain;
					object-position: center;
				}
			}

			.news-wrapper{
				margin-top: 20px;
				display: grid;
				grid-template-columns: calc(50% - 10px) calc(50% - 10px);
				gap: 20px;

				article{

					iframe{
						width: 100%;
						height: calc(100vh - 150px - 30px - 180px - 75px);
						// height: 450px;
					}

					.image{
						margin-bottom:  30px;
						position: relative;
						padding-top: 56.25%;

						img{
							position: absolute;
							top: 0;
							left: 0;
							object-fit: cover;
							object-position: center;
							width: 100%;
							height: 100%;
						}
					}

					.title{
						font-size: 18px;
						color: var(--color1);
						font-weight: bold;
						margin-bottom:  20px;
					}

					.content{
						font-size: 16px;
					}

					.btn-1{
						margin-top: 25px;
					}
				}
			}
		}

		.col2{

			.title{
				font-size: 24px;
				flex: 0;
				font-weight: normal;
				margin: 0;

				span{}

				b{
					color: var(--color1);
				}
			}

			.news-girl{
				max-width: 300px;
			}

			.btn-1.know-more{
				margin-top: 0;
			}
		}

	}

	@media (max-width: 1400px){

		section{
			padding: 50px 80px 50px 50px;
		}

	}

	@media (max-width: 991px){

		section{
			grid-template-columns: 100%;
			gap: 0;
			min-height: 0;

			.col1{
				order: 2;

				.banner{
					display: none;
				}
			}

			.col2{

				.news-girl{
					display: none;
				}
			}
		}

	}

	@media (max-width: 768px){

		section{
			padding: 25px;

			.col1{

				.news-wrapper{
					grid-template-columns: 100%;
					gap: 0;

					article{
						margin-bottom: 50px;
					}
				}
			}
		}

	}
</style>
