<template>
	<transition name="fade">
		<div id="cookie-banner" v-if="active">
			<div v-html="$root.translate('We use cookies. By continuing to browse, you <strong>accept</strong> our settings and terms of use.')"></div>
			<div>
				<button type="button" v-on:click="accept">{{ $root.translate('Accept') }}</button>
				<a @click="$emit('show')">
					{{ $root.translate('Options') }}
				</a>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: "Cookies",
	data: {
		active: false
	},
	mounted: function(){
		let c = this.getCookie("mmlaw_cookies");
		if(c == "" || c != "accepted"){
			this.active = true;
		}
	},
	methods: {
		accept: function(){
			this.setCookie("mmlaw_cookies", "accepted", 365);
			this.active = false;
      this.$emit('accept')
		},
		setCookie(cname, cvalue, exdays){
			const d = new Date();
			d.setTime(d.getTime() + (exdays*24*60*60*1000));
			let expires = "expires="+ d.toUTCString();
			document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
		},
		getCookie(cname){
			let name = cname + "=";
			let decodedCookie = decodeURIComponent(document.cookie);
			let ca = decodedCookie.split(';');
			for(let i = 0; i <ca.length; i++){
				let c = ca[i];
				while(c.charAt(0) == ' '){
					c = c.substring(1);
				}
				if(c.indexOf(name) == 0){
					return c.substring(name.length, c.length);
				}
			}
			return "";
		}
	}
};
</script>

<style scoped lang="scss">

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s ease;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}

	#cookie-banner{
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 15px;
		background-color: #000;
		color: #FFF;
		z-index: 10000;
		display: flex;
		justify-content: space-between;
		align-items: center;

		button,
		a{
			font-size: 14px;
			border: 2px solid #FFF;
			padding: 5px 25px;
			background: transparent;
			outline: none;
			color: #FFF;
			font-weight: bold;
			border-radius: 5px;
			transition: background-color 0.3s, color 0.3s;
			cursor: pointer;
			text-decoration: none;
			margin: 5px 10px;
			display: inline-block;

			&:hover{
				background-color: #FFF;
				color: #000;
			}
		}
	}

</style>
