<template>
	<li class="tab-item" :class="{active: isActive}">
		<button type="button" @click="toggle">
			<slot></slot>
		</button>
		<span class="mark"></span>
	</li>
</template>

<script>
export default {
	name: "TabItem",
	inject: ["activeTab"],
	props: {
		id: String,
		active: Boolean
	},
	computed: {
		isActive: function(){
			return this.activeTab == this.id;
		}
	},
	mounted: function(){
		if(this.active){
			this.activeTab = this.id;
		}
	},
	methods: {
		toggle: function(){
			this.activeTab = this.id;
		}
	}
};
</script>

<style scoped lang="scss">
	.tab-item{
		display: inline-block;
		margin: 0 5px;
		padding-bottom: 10px;
		position: relative;

		&:first-child{
			margin-left: 0;
		}

		&:last-child{
			margin-right: 0;
		}

		button{
			background-color: transparent;
			width: 50px;
			height: 50px;
			margin: 0;
			padding: 0;
			outline: none;
			cursor: pointer;
			border: 0;
		}

		.mark{
			position: absolute;
			bottom: 0;
			left: 0;
			width: 0;
			height: 2px;
			background: var(--color1);
			transition: width 0.3s;
		}

		&:hover,
		&.active{

			.mark{
				width: 100%;
			}
		}

		&.type-button{
			margin: 0 5px;

			.mark{
				display: none;
			}

			button{
				color: #000;
				font-size: 18px;
				border-width: 1px;
				border-style: solid;
				border-color: #000;
				border-radius: 999px;
				transition: color 0.3s, border-color 0.3s;
			}

			&:hover,
			&.active{

				button{
					color: var(--color1);
					border-color: var(--color1);
				}
			}
		}

		&.testimonies{
			display: block;
			margin: 0;

			button{
				width: auto;
				height: auto;
				transition: color 0.3s;
				font-size: 18px;
				color: #000;
			}

			&:hover,
			&.active{

				button{
					color: var(--color1);
				}
			}

			.mark{
				display: none;
			}
		}
	}

	@media (max-width: 768px){

		.tab-item{

			button{
				width: 35px;
				height: 35px;
			}
		}

	}
</style>
