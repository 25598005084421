<template>
	<div class="curriculum-wrapper">
		<button type="button" class="name" :class="{ active: active }" v-on:click="show">{{ name }}</button>
		<transition name="fade">
			<div class="curriculum-content" v-if="active">
				<div class="col1">
					<div class="int-name">{{ name }}</div>
					<div class="position">{{ position }}</div>
					<div class="mobile image" v-on:mouseover="imgHover = true" v-on:mouseleave="imgHover = false">
						<img :src="imageMobile" class="img-fluid" />
					</div>
					<slot/>
					<a v-if="linkedin != null && linkedin !== ''" :href="linkedin" class="btn-1" target="_blank">{{ $root.translate("Connect via Linkedin") }}</a>
				</div>
				<div class="col2" :style="imgStyles" v-on:mouseover="imgHover = true" v-on:mouseleave="imgHover = false"></div>
				<button type="button" class="btn-close" v-on:click="hide">
					<svg viewBox="0 0 100 100">
	                    <line x1="0" y1="0" x2="100" y2="100" />
	                    <line x1="0" y1="100" x2="100" y2="00" />
	                </svg>
				</button>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	name: "Curriculum",
	props: {
		name: String,
		position: String,
		image: String,
		h_image: String,
		linkedin: String
	},
	data: function(){
		return {
			active: false,
			imgHover: false
		}
	},
	computed: {
		imgStyles: function(){
			if(this.imgHover){
				return 'background-image: url(' + this.h_image + ')';
			}
			return 'background-image: url(' + this.image + ')';
		},
		imageMobile: function(){
			if(this.imgHover){
				return this.h_image;
			}
			return this.image;
		}
	},
	methods: {
		show: function(){
			this.active = true;
			if(window.innerWidth > 768){
				document.querySelector('body').classList.add('noscroll');
			}
		},
		hide: function(){
			this.active = false;
			document.querySelector('body').classList.remove('noscroll');
		}
	}
};
</script>

<style scoped lang="scss">

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s ease;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}

	.curriculum-wrapper{

		.name{
			cursor: pointer;
			background: transparent;
			border: 0;
			padding: 0;
			margin: 0 0 10px 0;
			transition: color 0.3s;
			font-size: 18px;
			color: #000;

			&.active,
			&:hover{
				color: var(--color1);
			}
		}

		.curriculum-content{
			position: fixed;
			width: 100%;
			min-height: 100vh;
			top: 0;
			left: 0;
			z-index: 2000;
			background: #FFF;
			// padding: 140px 60px;
			display: flex;
			// gap: 60px;
			justify-content: space-between;
			align-items: stretch;

			.col1{
				width: 50%;
				padding: 6%;
				align-self: center;

				.int-name{
					font-weight: bold;
				}

				.position{
					margin-bottom: 30px;
					font-style: italic;
				}
			}

			.col2{
				width: 50%;
				background-position: bottom right;
				background-size: contain;
				background-repeat: no-repeat;
			}

			.btn-close{
				border: 1px solid #000;
				cursor: pointer;
				border-radius: 999px;
				height: 50px;
				width: 50px;
				position: absolute;
				top: 40px;
				right: 40px;
				background-color: transparent;
				padding: 12px;

				svg{
					width: 100%;
                    height: 100%;

                    line{
                        stroke: #000;
                        stroke-width: 5px;
                        transition: stroke 0.3s;
                    }
				}
			}
		}
	}

	@media (max-width: 768px){

		.curriculum-wrapper{

			.curriculum-content{
				position: relative;
				min-height: 0;
				// padding: 10px 0 0 0;
				flex-direction: column;
				gap: 0;
				margin-bottom: 40px;

				.btn-close{
					width: 30px;
					height: 30px;
					top: -30px;
					right: 0;
					padding: 6px;
				}

				.col1{
					width: 100%;
					padding: 0;

					.int-name{
						display: none;
					}
				}

				.col2{
					display: none;
				}
			}
		}

	}
</style>
