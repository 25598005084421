<template>

  <!--  <Cookies :active="1"-->
  <!--           @show="showCookiesText"-->
  <!--           @accept="hideText"-->
  <!--           v-show="loaded"-->
  <!--  />-->

  <div v-if="isLandscape" id="portrait-view">
    <div>
      <img class="logo" src="/static-assets/logo-white.svg"/>
    </div>

    <div>
      <img class="change-view" src="/static-assets/icons/portrait_view.svg"/>
    </div>

    <div>
      <h3>
        Pre lepší zážitok z prehliadania, otočte prosím orientáciu zariadenia!
      </h3>
      <h3>
        For a better browsing experience, please rotate the orientation of your device!
      </h3>
    </div>

  </div>

  <div v-else>
    <div id="langSwitcher-container">
      <button
          type="button"
          class="langSwitcher"
          v-for="locale in switcherLocales"
          :key="'btn-swich-loclae-' + locale"
          v-on:click="switchLangTo(locale); homeTitle.reset = true">
        {{ locale }}
      </button>
    </div>

    <div id="nav-container">

      <div class="controls">
        <a href="#home" class="logoBackTop" v-if="sectionActive != 9">
          <img src="/static-assets/logo-min.svg"/>
        </a>
        <button type="button" class="menuToggler" v-on:click="menuActive = !menuActive">
          <svg viewBox="0 0 100 100">
            <line x1="0" y1="10" x2="100" y2="10"/>
            <line x1="0" y1="50" x2="100" y2="50"/>
            <line x1="0" y1="90" x2="100" y2="90"/>
          </svg>
        </button>
      </div>
      <div class="nav-wrapper" :class="{ active: menuActive }">
        <nav>
          <ul class="main-menu">
            <li class="langSwitcher-wrapper">
              <button
                  type="button"
                  class="langSwitcher"
                  v-for="locale in switcherLocales"
                  :key="'btn-swich-loclae-' + locale"
                  v-on:click="switchLangTo(locale); homeTitle.reset = true">
                {{ locale }}
              </button>
            </li>
            <li>
              <a href="#home" :class="{ active: sectionActive == 0 }" v-on:click="menuActive = false">{{
                  translate('Home')
                }}</a>
            </li>
            <li>
              <span>{{ translate('Our solutions for') }}</span>
              <ul class="submenu">
                <li>
                  <a href="#our-solutions-for-technology-firms" :class="{ active: sectionActive == 1 }"
                     v-on:click="menuActive = false">{{ translate('Technology firms') }}</a>
                </li>
                <li>
                  <a href="#our-solutions-for-real-estate-developers" :class="{ active: sectionActive == 2 }"
                     v-on:click="menuActive = false">{{ translate('Real estate developers') }}</a>
                </li>
                <li>
                  <a href="#our-solutions-for-entrepreneurs-in-regulated-industries"
                     :class="{ active: sectionActive == 3 }" v-on:click="menuActive = false">{{
                      translate('Regulated entities')
                    }}</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="#more-about" :class="{ active: sectionActive == 4 }" v-on:click="menuActive = false">{{
                  translate('More about us')
                }}</a>
            </li>
            <li>
              <a href="#our-team" :class="{ active: sectionActive == 5 }" v-on:click="menuActive = false">{{
                  translate('Our team')
                }}</a>
            </li>
            <li>
              <a href="#our-culture" :class="{ active: sectionActive == 6 }" v-on:click="menuActive = false">{{
                  translate('Our culture')
                }}</a>
            </li>
            <li>
              <a href="#what-they-say-about-us" :class="{ active: sectionActive == 7 }"
                 v-on:click="menuActive = false">{{ translate('What they say about us') }}</a>
            </li>
            <li>
              <a href="#read-the-news" :class="{ active: sectionActive == 8 }"
                 v-on:click="menuActive = false">{{ translate('Read the news') }}</a>
            </li>
            <li>
              <a href="#contact" :class="{ active: sectionActive == 9 }" v-on:click="menuActive = false">{{
                  translate('Contact')
                }}</a>
            </li>
          </ul>

          <div class="flex">
            <button id="accessiblity-button" type="button" class="ml-auto btn-link"
                    @click="handleEnableAccessibilityMode"
            >
              {{ translate('Accessibility mode') }}
              <img src="/static-assets/accessibility.svg" alt="Accessiblity"/>
            </button>
          </div>
        </nav>
      </div>
    </div>

    <div id="nav-bullet-container">
      <div class="nav-wrapper">
        <nav>
          <ul>
            <li>
              <a href="#home" :class="{ active: sectionActive == 0 }" v-on:click="menuActive = false"></a>
            </li>
            <li>
              <a href="#our-solutions-for-technology-firms" :class="{ active: sectionActive == 1 }"
                 v-on:click="menuActive = false"></a>
            </li>
            <li>
              <a href="#our-solutions-for-real-estate-developers" :class="{ active: sectionActive == 2 }"
                 v-on:click="menuActive = false"></a>
            </li>
            <li>
              <a href="#our-solutions-for-entrepreneurs-in-regulated-industries"
                 :class="{ active: sectionActive == 3 }"
                 v-on:click="menuActive = false"></a>
            </li>
            <li>
              <a href="#more-about" :class="{ active: sectionActive == 4 }" v-on:click="menuActive = false"></a>
            </li>
            <li>
              <a href="#our-team" :class="{ active: sectionActive == 5 }" v-on:click="menuActive = false"></a>
            </li>
            <li>
              <a href="#our-culture" :class="{ active: sectionActive == 6 }" v-on:click="menuActive = false"></a>
            </li>
            <li>
              <a href="#what-they-say-about-us" :class="{ active: sectionActive == 7 }"
                 v-on:click="menuActive = false"></a>
            </li>
            <li>
              <a href="#read-the-news" :class="{ active: sectionActive == 8 }" v-on:click="menuActive = false"></a>
            </li>
            <li>
              <a href="#contact" :class="{ active: sectionActive == 9 }" v-on:click="menuActive = false"></a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <section id="home" :style="styleHome">
      <img class="logo" src="/static-assets/logo-white.svg"/>
      <div class="quote-container">
        <div class="homeTitle">
          <span>{{ translate('Legal advice for') }}</span><br/>
          <b>{{ homeTitle.contents }}</b>
        </div>
      </div>
    </section>

    <SectionTemplate1
        id="our-solutions-for-technology-firms"
        :title="translate('Our solutions for')"
        :subtitle="translate('Technology firms')"
        :image="galleries.technology.images[galleries.technology.current]"
        :cta-link="translate('https://form.typeform.com/to/XHAv4E0F')"
        :cta-text="translate('Know more')">

      <template v-slot:intro>
        {{
          translate(
              'We advise the whole spectrum of innovators: from individuals with business ideas and the ambition to bring them to life to corporations that were once start-ups but have grown into successful businesses.')
        }}
      </template>

      <template v-slot:tabs>
        <tabs>
          <template v-slot:tab-items>
            <tab-item id="0" v-on:click="galleries.technology.current = 0" active><img
                src="/static-assets/icons/1-01.svg"
                class="img-fluid"/></tab-item>
            <tab-item id="1" v-on:click="galleries.technology.current = 1"><img
                :src="'/static-assets/icons/' + translate('1-02-en.svg')" class="img-fluid"/></tab-item>
            <tab-item id="2" v-on:click="galleries.technology.current = 2"><img src="/static-assets/icons/1-03.svg"
                                                                                class="img-fluid"/></tab-item>
            <tab-item id="3" v-on:click="galleries.technology.current = 3"><img src="/static-assets/icons/1-04.svg"
                                                                                class="img-fluid"/></tab-item>
            <tab-item id="4" v-on:click="galleries.technology.current = 4"><img src="/static-assets/icons/1-05.svg"
                                                                                class="img-fluid"/></tab-item>
          </template>
          <template v-slot:tab-contents>
            <tab-content id="0" :title="translate('IP / IT')">
              {{
                translate(
                    'How is an idea converted into successful technology? Apart from the creation of the legal framework for your company, we will advise you on the protection of your intellectual property, on setting up your relationships with inventors and innovators and on structuring sales models.')
              }}
            </tab-content>
            <tab-content id="1" :title="translate('Investment and exit')">
              {{
                translate(
                    'We help investors identify and solve problems in target companies efficiently and quickly. We also help founders to prepare for exit from their companies or investments. We draft and negotiate transaction documentation. We look for solutions. Our greatest satisfaction is seeing all parties happy with the result.')
              }}<br/>
              <br/>
              {{ translate('We use our network to introduce founders to investors and vice versa.') }}
            </tab-content>
            <tab-content id="2" :title="translate('Contracts')">
              {{
                translate(
                    'Each contract has its own life cycle. Let us be your guide. It is a long journey from understanding the basic commercial terms, through the drafting of contracts, obtaining internal approvals and the negotiation of the final version with the counterparty. Even when the contract is concluded it is just the beginning. You need to constantly be aware of your continuing obligations from the contracts, fulfil them and sometimes enforce them in court. We will help you understand complex contractual terms. We will ask you many questions to ensure that we understand what you really want to achieve. Throughout this process we will provide you with points of view that may not have previously occurred to you.')
              }}
            </tab-content>
            <tab-content id="3" :title="translate('Employment agenda')">
              {{
                translate(
                    'People are the key to every successful project. We feel that as the business environment gradually evolves, the transparent and fair treatment of employees is a fundamental building block of the reputation for every successful firm. Information technology and the rapidly changing labour market bring new global challenges. Swift reactions are a must. We are here to help you navigate these stormy waters.')
              }}
            </tab-content>
            <tab-content id="4" :title="translate('Personal data protection')">
              {{
                translate(
                    'Personal data protection is not a question of merely filing simple documents into the company’s archives. It is a landscape that will not disappear - it will become more sensitive and it will be ever more closely scrutinised. That is why companies must approach this field systematically and fairly, through the implementation of internal processes and discipline in compliance with such processes. We will explain what you need to do, help you to understand the regulations and draft the necessary documents for you.')
              }}
            </tab-content>
          </template>
        </tabs>
      </template>
    </SectionTemplate1>

    <SectionTemplate1
        id="our-solutions-for-real-estate-developers"
        class="inverted"
        :title="translate('Our solutions for')"
        :subtitle="translate('Real estate developers')"
        :image="galleries.realstate.images[galleries.realstate.current]"
        :cta-link="translate('https://form.typeform.com/to/PFavVq3y')"
        :cta-text="translate('Know more')">

      <template v-slot:intro>
        {{
          translate(
              'Our clients are smaller developers focusing on residential projects as well as large investment funds with long-term experience of the development of commercial malls, administrative complexes or well-known residential projects in downtown areas.')
        }}
      </template>

      <template v-slot:tabs>
        <tabs>
          <template v-slot:tab-items>
            <tab-item id="0" v-on:click="galleries.realstate.current = 0" active><img
                src="/static-assets/icons/2-01.svg"
                class="img-fluid"/></tab-item>
            <tab-item id="1" v-on:click="galleries.realstate.current = 1"><img src="/static-assets/icons/2-02.svg"
                                                                               class="img-fluid"/></tab-item>
            <tab-item id="2" v-on:click="galleries.realstate.current = 2"><img src="/static-assets/icons/2-03.svg"
                                                                               class="img-fluid"/></tab-item>
          </template>
          <template v-slot:tab-contents>
            <tab-content id="0" :title="translate('Development')">
              {{
                translate(
                    'We understand the dangers that developers face. We will help you with setting up the best shareholding structure and the acquisition of construction plots. We will provide support for structuring and negotiating contracts with suppliers. Each project has its own specifics, whether it is archaeological discoveries, environmental burdens or problems with access. We know that you expect us to provide swift solutions as you are under pressure from suppliers, financing banks and your clients.')
              }}
            </tab-content>
            <tab-content id="1" :title="translate('Purchase and sale')">
              {{
                translate(
                    'We have been advising clients on the purchase and sale of real estate (whether through asset deals or share deals) for a very long time. Nationalisation, privatisation, restitution, land adjustments, complex land legislation, limited due diligence options, duplicate ownership, inconsistent practices of cadastral offices or inexperienced real estate agents – this is only a brief catalogue of problems that we have encountered. Legal defects are one of the most notorious problems of real estate. We will assist you in making an informed decision on any contemplated purchase or sale.')
              }}
            </tab-content>
            <tab-content id="2" :title="translate('Operation/lease')">
              {{
                translate(
                    'The construction of a residential, commercial or administrative development is an enormous undertaking. We know that even if this is completed, the crucial work just starts. Agreements on future lease contracts, leases, fit outs, guarantees, warranties, enforcement of claims for damages, operations manuals and property management are all very complex topics. The quality of the contracts drafted will sooner or later become important. In every large construction project there will inevitably be some disputes. We will help you to arrange your legal relationships in the best possible way, with the objective of avoiding disputes. We will help you to foresee potential problems and we will propose contractual solutions. If none are available, we will advise you how to proceed.')
              }}
            </tab-content>
          </template>
        </tabs>
      </template>
    </SectionTemplate1>

    <SectionTemplate1
        id="our-solutions-for-entrepreneurs-in-regulated-industries"
        :title="translate('Our solutions for')"
        :subtitle="translate('Regulated entities')"
        :image="galleries.regulated.images[galleries.regulated.current]"
        :cta-link="translate('https://form.typeform.com/to/H0rbnofB')"
        :cta-text="translate('Know more')">

      <template v-slot:intro>
        {{
          translate(
              'We enjoy advising our clients operating in various industries, whether those industries are heavily regulated or where regulation is just being developed. We find it especially interesting when we advise on innovative projects which disrupt traditional business models.')
        }}
      </template>

      <template v-slot:tabs>
        <tabs>
          <template v-slot:tab-items>
            <tab-item id="0" v-on:click="galleries.regulated.current = 0" active><img
                :src="'/static-assets/icons/' + translate('3-01-en.svg')" class="img-fluid"/></tab-item>
            <tab-item id="1" v-on:click="galleries.regulated.current = 1"><img src="/static-assets/icons/3-02.svg"
                                                                               class="img-fluid"/></tab-item>
            <tab-item id="2" v-on:click="galleries.regulated.current = 2"><img src="/static-assets/icons/3-03.svg"
                                                                               class="img-fluid"/></tab-item>
            <tab-item id="3" v-on:click="galleries.regulated.current = 3"><img src="/static-assets/icons/3-04.svg"
                                                                               class="img-fluid"/></tab-item>
          </template>
          <template v-slot:tab-contents>
            <tab-content id="0" :title="translate('Financial services (insurance, fintech)')">
              {{
                translate(
                    'In particular, we advise fintechs and insurance companies. As part of this work we assess new business models and their compliance with legislation, we prepare legal analysis on disputed issues, we set-up and localise insurance terms, advise on reorganisation, restructuring and transfers of insurance portfolios.')
              }}
            </tab-content>
            <tab-content id="1" :title="translate('Energy')">
              {{
                translate(
                    'Apart from standard regulatory advisory work, in the last few years we have also focused on renewable energy, in particular on the development, sale and purchase of renewable energy sources. In addition, we provide advice to energy suppliers and global fuel station network.')
              }}
            </tab-content>
            <tab-content id="2" :title="translate('Mineral deposits / mining')">
              {{
                translate(
                    'We have experience with the interpretation and application of mining and geological legislation at every level of state administration. In this field we have also advised on international arbitration.')
              }}
            </tab-content>
            <tab-content id="3" :title="translate('Retail')">
              {{
                translate(
                    'Retail, loyalty programmes, consumer protection, advertising, competition and franchise schemes are areas with which we and our clients are very familiar. As in every industry, the retail industry is being transformed: electronic trade and remote sales are increasingly important to our retail clients.')
              }}
            </tab-content>
          </template>
        </tabs>
      </template>
    </SectionTemplate1>

    <SectionTemplate1
        id="more-about"
        class="inverted"
        :title="translate('More')"
        :subtitle="translate('About us')"
        image="/static-assets/illustrations/moreabout_en.svg"
        cta-link=""
        cta-text="">

      <template v-slot:intro>
        {{
          translate(
              'We feel responsible for the success of every project. Once we understand the business and objectives of our clients we propose solutions. We are tenacious and relentless; we push things forward. We react swiftly and try to set realistic expectations. Our clients can lean on us. We do not give empty promises. If we make a promise, we honour it.')
        }}
      </template>

      <template v-slot:tabs>
        <tabs>
          <template v-slot:tab-items>
            <tab-item id="1" active><img src="/static-assets/icons/4-01.svg" class="img-fluid"/></tab-item>
            <tab-item id="2"><img src="/static-assets/icons/4-02.svg" class="img-fluid"/></tab-item>
            <tab-item id="3"><img src="/static-assets/icons/4-03.svg" class="img-fluid"/></tab-item>
            <tab-item id="4"><img src="/static-assets/icons/4-04.svg" class="img-fluid"/></tab-item>
          </template>
          <template v-slot:tab-contents>
            <tab-content id="1" :title="translate('Candour')">
              {{
                translate(
                    'We believe that society can only be improved through hard work and proper behaviour. We avoid becoming involved with any matters which lack transparency. We want to contribute to improving standards in our society, so that transparent and fair behaviour is rewarded and comes to be considered the norm, rather than the exception.')
              }}
            </tab-content>
            <tab-content id="2" :title="translate('Hard work')">
              {{
                translate(
                    'Hard work and attention to detail are in direct opposition to mental laziness. The pursuit of these qualities is a decision that one has to make consciously. We have made this decision. We are willing to get out of our comfort zone and do things better and faster.')
              }}
            </tab-content>
            <tab-content id="3" :title="translate('Innovation')">
              {{
                translate(
                    'We closely observe and, with enthusiasm, implement new technologies. Thanks to them, we can make the lives of our clients easier and our work more efficient. We can be fully focused on results.')
              }}<br/>
              <br/>
              {{
                translate(
                    'It is wonderful to be part of something great from the very beginning, to be surrounded by innovative and creative people. This is the reason why we have been helping Slovak start-ups to grow and to conquer the world since 2008.')
              }}
            </tab-content>
            <tab-content id="4" :title="translate('Responsibility')">
              {{
                translate(
                    'We believe in observing the rules of social responsibility. Even though we cannot transform the entire society, together with our clients we try to improve the environment that surrounds us. Responsibility is not only to meet deadlines, but above all, to bear the consequences of our actions.')
              }}
            </tab-content>
          </template>
        </tabs>
      </template>
    </SectionTemplate1>

    <SectionTemplate1
        id="our-team"
        :title="translate('Our')"
        :subtitle="translate('Team')"
        image="/static-assets/team.jpg"
        cta-link=""
        cta-text="">

      <template v-slot:intro>
        {{
          translate(
              'Through complex projects teamwork is perfected.  We support an atmosphere of co-operation and we believe that the greatest added value within the team is willingness to help each other, share opinions and brainstorm. We can rely on each other as professionals as well as human beings.')
        }}
      </template>

      <template v-slot:tabs>
        <tabs>
          <template v-slot:tab-items>
            <tab-item v-for="(category, index) in teamCategories"
                      :key="index"
                      :id="index"
            >
              <img :src="strapiImage(category.image)" class="img-fluid"/>
            </tab-item>
          </template>
          <template v-slot:tab-contents>
            <tab-content v-for="(category, index) in teamCategories"
                         :id="index"
                         :title="category.title[currentLocale]"
            >
              <curriculum v-for="(person, j) in category.team_people"
                          :key="j"
                          :name="person.name"
                          :position="person.position[currentLocale]"
                          :image="strapiImage(person.photo_professional)"
                          :h_image="strapiImage(person.photo_casual)"
                          :linkedin="person.linkedin"

              >
                <div v-html="person.text[currentLocale]"/>
              </curriculum>

            </tab-content>

          </template>
        </tabs>
      </template>
    </SectionTemplate1>

    <SectionTemplate1
        id="our-culture"
        class="inverted"
        :title="translate('Our2')"
        :subtitle="translate('Culture')"
        image="/static-assets/illustrations/culture_en.svg"
        cta-link=""
        cta-text="">

      <template v-slot:intro>
        {{
          translate(
              'Our lawyers and staff are firstly human beings and only then professionals. We appreciate open communication and set realistic goals. We support our people in sharing know-how because we believe that it is key to the creation of a team of strong professionals.')
        }}<br/>
        <br/>
        {{
          translate(
              'We respect a proper life-work balance and we distribute legal work with the aims of achieving the highest possible quality of result and avoiding unnecessary stress. Our former and current colleagues all appreciate the excellent relationships within the firm.')
        }}
      </template>

      <template v-slot:tabs>
        <p class="txt-2">
          {{ translate('Would you like to join us?') }}
        </p>
        <button type="button" v-on:click="openTypeform('https://form.typeform.com/to/XzPsYafC')" class="btn-1">
          {{ translate('Tell us something about you') }}
        </button>
      </template>
    </SectionTemplate1>

    <Testimonies
        id="what-they-say-about-us"
        :title="translate('What they say')"
        :subtitle="translate('About us')"
        :cta-link="translate('https://form.typeform.com/c/F3s4vOom')"
        :cta-text="translate('Let\'s connect!')">

      <tabs class="testimonies">
        <template v-slot:tab-items>
          <tab-item v-for="(testimonial, index) in testimonials"
                    :key="index"
                    :id="testimonial.id"
                    class="testimonies"
                    :active="index === 0"
          >
            {{ testimonial.company }}
          </tab-item>
        </template>
        <template v-slot:tab-contents>
          <tab-content v-for="(testimonal, index) in testimonials"
                       :key="index"
                       :id="testimonal.id"
                       class="testimonies"
                       :title="testimonal.name"
                       :subtitle="testimonal.position"
                       background-image="/static-assets/whattheysay.jpg"
                       :testimony-image="strapiImage(testimonal.photo)"
          >
            {{ testimonal.text[currentLocale] }}
          </tab-content>
        </template>
      </tabs>

    </Testimonies>

    <News
        id="read-the-news"
        :title="translate('Read')"
        :subtitle="translate('The news')"
        cta-link=""
        cta-text=""
        :news="news"
    >
    </News>

    <Contact id="contact"
             ref="contact"
             :locale="currentLocale"
             :cdi="CDI"
             :privacy-policy="privacyPolicy"
             :cookies-policy="cookiesPolicy"
    />

  </div>

  <Typeform :active="typeform.active" :src="typeform.src"/>

</template>

<script>

// import { computed } from "vue";
import Cookies from './components/Cookies.vue'
import SectionTemplate1 from './components/SectionTemplate1.vue'
import Testimonies from './components/Testimonies.vue'
import News from './components/News.vue'
import Contact from './components/Contact.vue'
import Tabs from './components/Tabs.vue'
import TabItem from './components/TabItem.vue'
import TabContent from './components/TabContent.vue'
import Curriculum from './components/Curriculum.vue'
import Typeform from './components/Typeform.vue'

export default {
  name: 'App',
  components: {
    Cookies,
    SectionTemplate1,
    Testimonies,
    News,
    Contact,
    Tabs,
    TabItem,
    TabContent,
    Curriculum,
    Typeform,
  },
  data: function () {
    return {
      loaded: false,
      win: {
        width: window.innerWidth,
      },
      menuActive: false,
      sectionActive: 0,
      mouseWheelLocked: false,
      typeform: {
        active: false,
        src: '',
      },
      homeTitle: {
        reset: false,
        active: 0,
        minTimeout: 50,
        maxTimeout: 100,
        contents: '',
        breakTime: 1500, // 0 = no-repeat
        options: [
          'Technology firms',
          'Real estate developers',
          'Regulated entities',
        ],
      },
      galleries: {
        technology: {
          current: 0,
          images: [
            '/static-assets/illustrations/ip-it.svg',
            '/static-assets/illustrations/investment.svg',
            '/static-assets/illustrations/contracts.svg',
            '/static-assets/illustrations/employment.svg',
            '/static-assets/illustrations/personal.svg',
          ],
        },
        realstate: {
          current: 0,
          images: [
            '/static-assets/illustrations/development_en.svg',
            '/static-assets/illustrations/sale_en.svg',
            '/static-assets/illustrations/rent_en.svg',
          ],
        },
        regulated: {
          current: 0,
          images: [
            '/static-assets/illustrations/financial.svg',
            '/static-assets/illustrations/energy.svg',
            '/static-assets/illustrations/minery.svg',
            '/static-assets/illustrations/retail.svg',
          ],
        },
      },
      teamCategories: [],
      testimonials: [],
      news: null,
      CDI: '',
      privacyPolicy: '',
      cookiesPolicy: '',
    }
  },
  computed: {
    styleHome: function () {
      if (this.win.width < 768) {
        return 'background-image: url(\'/static-assets/illustrations/home-mobile.svg\'); background-size: contain;'
      }
      return 'background-image: url(\'/static-assets/illustrations/home.svg\')'
    },
  },
  created: async function () {
    await this.fetchData()

    window.addEventListener('resize', this.onWindowResize)
    window.addEventListener('scroll', this.handleScroll)
    if (!this.isMobile()) {
      window.addEventListener('wheel', this.handleMouseWheel, {
        passive: false,
      })
    }

    const params = new URLSearchParams(window.location.search)
    if (params.get('cookies') === '1') {
      this.showCookiesText()

      window.history.replaceState({}, document.title, "/" );
    }
  },
  beforeCreate() {
    const lang = sessionStorage.getItem('lang') || this.defaultLocale

    document.querySelector('html').setAttribute('lang', lang)
    document.querySelector('#cookie-script').setAttribute('data-cs-lang', lang)
  },
  mounted: function () {
    this.animateHomeTitle()
    this.handleScroll()
  },
  unmounted: function () {
    window.removeEventListener('scroll', this.handleScroll)
    if (!this.isMobile()) {
      window.removeEventListener('wheel', this.handleMouseWheel)
    }
  },
  methods: {
    onWindowResize: function () {
      this.win.width = window.innerWidth
    },
    handleScroll: function () {
      // console.log("handleScroll");
      var self = this
      let els = document.querySelectorAll('section')
      els.forEach(function (el, index) {
        let elTop = el.getBoundingClientRect().top
        let elBottom = el.getBoundingClientRect().bottom
        if (Math.floor(elTop) <= 0 && Math.ceil(elBottom) >= 0) {
          self.sectionActive = index
          // self.changeSection();
        }
      })
    },
    handleMouseWheel: function (event) {
      // console.log("handleMouseWheel");
      if (document.querySelector('#cookiescript_fsd_wrapper') || document.querySelector('#contact .legal-text')) {
        return
      }

      if (this.mouseWheelLocked) {
        event.preventDefault()
      } else {
        let el = document.querySelectorAll('section')[this.sectionActive]
        let elTop = el.getBoundingClientRect().top
        let elBottom = el.getBoundingClientRect().bottom
        let winh = window.innerHeight
        let moveTo = -1

        let delta = Math.sign(event.deltaY)

        // console.log(this.sectionActive, elTop, elBottom, winh, delta);

        if ((delta == 1 && (elBottom - winh) <= 0) || (delta == -1 && elTop >= 0)) {
          if (delta == 1) {
            // Down
            if (this.sectionActive < (document.querySelectorAll('#nav-bullet-container ul li').length - 1)) {
              moveTo = this.sectionActive + 1
            }
          } else if (delta == -1) {
            // Up
            if (this.sectionActive > 0) {
              moveTo = this.sectionActive - 1
            }
          }
          if (moveTo != -1) {
            event.preventDefault()
            this.changeSection(moveTo)
          }
        }
      }
    },
    changeSection: function (section) {
      // console.log("changeSection!");
      var self = this
      this.mouseWheelLocked = true
      document.querySelectorAll('#nav-bullet-container a')[section].click()
      setTimeout(function () {
        self.mouseWheelLocked = false
      }, 600)
    },
    animateHomeTitle: function () {
      let currentHomeTitle = this.translate(this.homeTitle.options[this.homeTitle.active])
      if (this.homeTitle.reset) {
        this.homeTitle.reset = false
        this.homeTitle.contents = ''
      }
      this.homeTitle.contents += currentHomeTitle.charAt(this.homeTitle.contents.length)
      if (this.homeTitle.contents.length < currentHomeTitle.length) {
        setTimeout(this.animateHomeTitle,
            Math.floor((Math.random() * this.homeTitle.maxTimeout) + this.homeTitle.minTimeout))
      } else if (this.homeTitle.breakTime > 0) {
        this.homeTitle.active++
        if (this.homeTitle.active == this.homeTitle.options.length) {
          this.homeTitle.active = 0
        }
        this.homeTitle.reset = true
        setTimeout(this.animateHomeTitle, this.homeTitle.breakTime)
      }
    },
    async fetchData() {
      const API_URL = 'https://mmlaw.sk'
      const response = await fetch(`${API_URL}/api`)
      const data = await response.json()

      this.teamCategories = data.teamCategories
      this.testimonials = data.testimonials
      const {
        COMPULSORY_DISCLOSURE_OF_INFORMATION,
        COOKIES,
        PRIVACY_POLICY,
      } = data.legalTexts

      this.privacyPolicy = PRIVACY_POLICY.text
      this.cookiesPolicy = COOKIES.text
      this.CDI = COMPULSORY_DISCLOSURE_OF_INFORMATION.text

      this.news = data.linkedinNews

      this.loaded = true
    },
    strapiImage(url) {
      return `/storage/${url}`
    },
    hideText() {
      this.$refs.contact.hideText()
    },
    async showCookiesText() {
      await this.$refs.contact.showText(this.cookiesPolicy)

      // document.getElementById('legal-after').innerHTML = "<hr>" + document.getElementById("cookie-report").innerHTML
      //
      // document.getElementById("cookie-report").remove()
    },
    handleEnableAccessibilityMode() {
      window.location = `/accessible.html?lang=${this.currentLocale}`
    }
  },
}
</script>

<style lang="scss">

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;700&display=swap');

:root {
  --color1: #00A66C; // Green
  --color2: #ADB5BD; // Grey
  --color3: #06242C; // Dark green
}

* {
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;

  &.noscroll {
    overflow: hidden;
  }
}

button {
  font-family: 'Raleway', sans-serif;
}

.btn-1 {
  cursor: pointer;
  font-size: 14px;
  background-color: #FFF;
  margin-top: 50px;
  border: 1px solid var(--color1);
  padding: 15px 60px;
  display: inline-block;
  border-radius: 999px;
  color: var(--color1);
  text-decoration: none;
  transition: color 0.3s, background-color 0.3s;

  &:hover {
    background-color: var(--color1);
    color: #FFF;
  }
}

.img-fluid {
  max-width: 100%;
}

.mobile {
  display: none;
}

.my-50 {
  margin-top: 50px;
  margin-bottom: 50px;
}

.txt-2 {
  color: #707070;
  font-size: 18px;
}

#langSwitcher-container {
  position: absolute;
  z-index: 2000;
  top: 40px;
  right: 105px;

  .langSwitcher {
    display: block;
    width: 50px;
    height: 50px;
    background-color: #FFF;
    border: 1px solid #000;
    border-radius: 999px;
    font-size: 18px;
    line-height: 50px;
    padding: 0;
    margin: 0;
    cursor: pointer;
    outline: none;
    color: #000;
    transition: background-color 0.3s, color 0.3s;
    text-transform: uppercase;

    &:hover {
      background-color: #000;
      color: #FFF;
    }
  }
}

#nav-container {
  position: fixed;
  z-index: 1000;
  top: 40px;
  right: 40px;

  .controls {
    display: flex;
    position: relative;
    z-index: 1000;

    .logoBackTop {
      display: block;
      width: 50px;
      height: 50px;
      padding: 0;
      margin: 0;
      cursor: pointer;
      outline: none;
      color: #000;
      margin-right: 15px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .menuToggler {
      display: block;
      width: 50px;
      height: 50px;
      background-color: #FFF;
      border: 1px solid #000;
      border-radius: 999px;
      padding: 12px;
      margin: 0;
      cursor: pointer;
      outline: none;
      transition: background-color 0.3s;

      svg {
        width: 100%;
        height: 100%;

        line {
          stroke: #000;
          stroke-width: 5px;
          transition: stroke 0.3s;
        }
      }

      &:hover {
        background-color: #000;

        svg {

          line {
            stroke: #FFF;
          }
        }
      }
    }
  }

  .nav-wrapper {
    position: fixed;
    z-index: 900;
    top: 0;
    right: 100%;
    width: 100%;
    height: 100vh;
    background: var(--color1);
    transition: right 0.6s ease-in-out;

    &.active {
      right: 0;
    }

    nav {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: 0 15px;

      ul.main-menu {
        list-style: none;
        display: table;
        padding: 0;
        margin: 0 0 0 140px;

        li {
          margin: 12px 0;

          a {
            padding: 0;
            margin: 0;
            background-color: transparent;
            border: 0;
            outline: none;
            cursor: pointer;
            color: #FFF;
            font-size: 30px;
            transition: color 0.3s;
            text-decoration: none;

            &:hover,
            &.active {
              text-decoration: underline;
            }
          }

          span {
            padding: 0;
            margin: 0;
            background-color: transparent;
            border: 0;
            outline: none;
            color: #FFF;
            font-size: 30px;
            text-decoration: none;
          }

          ul.submenu {
            list-style: none;
            display: table;
            padding-left: 60px;

            li {

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          &.langSwitcher-wrapper {

            .langSwitcher {
              background: transparent;
              text-transform: uppercase;
              color: #FFF;
              font-size: 30px;
              border: 0;
              padding: 0;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

#nav-bullet-container {
  position: fixed;
  z-index: 1000;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);

  .nav-wrapper {

    nav {

      ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
          padding: 8px 0;

          a {
            display: block;
            width: 12px;
            height: 12px;
            padding: 0;
            margin: 0;
            border-radius: 999px;
            border: 1px solid rgba(0, 0, 0, 0.8);
            background-color: rgba(0, 0, 0, 0.8);
            outline: none;
            cursor: pointer;
            transition: background-color 0.3s, border-color 0.3s;

            &:hover,
            &.active {
              background-color: var(--color1);
              border: 1px solid #FFF;
            }
          }
        }
      }
    }
  }
}

#home {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .logo {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    margin: 0 auto;
    max-width: 400px;
  }

  .homeTitle {
    text-align: center;
    font-size: 40px;
    padding: 0 20px;

    span {
    }

    b {
      color: var(--color1);
    }
  }
}


#portrait-view {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  background-color: #EEEEEE;

  div .logo {
    width: 100%;
  }

  div .change-view {
    width: 60%;
  }

}

#accessiblity-button {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 20px;

  img {
    margin-left: 14px;
    width: 50px;
  }
}


.flex {
  display: flex
}

.ml-auto {
  margin-left: auto
}

.btn-link {
  border: none;
  background: none !important;
  color: #FFF;
}

@media (max-width: 1400px) {

  #langSwitcher-container {
    top: 15px;
    right: 80px;
  }

  #nav-container {
    top: 15px;
    right: 15px;

    .nav-wrapper {

      nav {

        ul {

          li {

            a {
              font-size: 28px;
            }
          }
        }
      }
    }
  }

  #nav-bullet-container {
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }

  #home {

    .logo {
      top: 0;
    }
  }

  #accessiblity-button {
    margin-right: -7px;
  }
}

@media (max-width: 991px) {

  #nav-container {

    .nav-wrapper {

      nav {

        ul.main-menu {
          margin: 0;
          padding: 0 60px;

          li,
          li.langSwitcher-wrapper {

            button.langSwitcher,
            span,
            a {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  #langSwitcher-container {
    top: 80px;
    right: 15px;
  }

  #nav-container {

    .controls {
      flex-direction: column-reverse;

      .logoBackTop {
        margin-right: 0;
        margin-top: 15px;
      }
    }

    .nav-wrapper {

      nav {

        ul.main-menu {
          padding: 15px;

          li {
            margin: 8px 0;
          }
        }
      }
    }
  }

  #nav-bullet-container {
    display: none;
  }

  #home {

    .logo {
      top: 0;
      width: calc(100% - 140px);
    }
  }

  #accessiblity-button {
    font-size: 16px;
  }

}


</style>
