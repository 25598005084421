<template>
	<div class="tabs-wrapper">
		<ul class="tab-items-wrapper">
			<slot name="tab-items"></slot>
		</ul>
		<div class="tab-content-wrapper border border-top-0 p-3">
			<slot name="tab-contents"></slot>
		</div>
	</div>
</template>

<script>
import { ref, provide } from 'vue'
export default {
	name: "Tabs",
	setup: function(){
        provide('activeTab', ref(0));
    }
};
</script>

<style lang="scss">

	.tabs-wrapper{

		.tab-items-wrapper{
			list-style: none;
			padding: 0;
			margin: 0;
		}

		&.testimonies{
			display: flex;
			gap:40px;
			flex: 1;

			.tab-items-wrapper{

			}

			.tab-content-wrapper{
				flex: 1;
			}
		}
	}

	@media (max-width: 991px){

		.tabs-wrapper{

			.tab-items-wrapper{
				list-style: none;
				padding: 0;
				margin: 0;
			}

			&.testimonies{
				flex-direction: column;
				gap: 40px;
			}
		}
	}
</style>
